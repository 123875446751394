import React from 'react';
import * as restful from '../../api/restful-api';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';

const FileUploadContainer = styled.div`
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
`;

const FileUploadButton = styled.button`
  height: 38px;
  width: 120px;
  border: none;
  padding: 0;
  color: #fff;
  background-color: ${
    props => props.selectFile ? theme.mainLighterColor : props.removeFile ? theme.failureColor : theme.mainDarkColor
  };

  &:hover {
    background-color: ${
      props => props.selectFile ? theme.mainLightColor : props.removeFile ? theme.failureLightColor : theme.mainDarkColorHover
    }
  }
`;

const InstructionsMsg = styled.div`
  margin-top: 10px;
  color: ${theme.mainDeepColor};
`;

const InvalidMsg = styled.div`
  margin-top: 10px;
  color: ${theme.failureColor};
  font-size: 14px;
`;

const FilePreviewContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
`;

const ImgContainer = styled.img`
  max-height: ${props => props.height};
`;

const ProgressWrap = styled.div`
  height: 38px;
  background: #fff;
  width: 100%;
  position: relative;
`;

const ProgressInfo = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 10px;
`;

const ProgressBar = styled.div`
  height: 38px;
  background: ${theme.mainLightColor};
`;

export default class extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      progress: '0%',
			uploadedFileName: null,
			uploadedFileSize: 0,
			showSelectButton: true,
			showProgressBar: false,
			showRemoveButton: false,
			imgSizeLimit: this.props.imgSizeLimit ? this.props.imgSizeLimit : 3072,
			videoSizeLimit: this.props.videoSizeLimit ? this.props.videoSizeLimit : 100000,
      pdfSizeLimit: this.props.pdfSizeLimit ? this.props.pdfSizeLimit : 4096,
			msgInvalid: ''
    };
    this.fileSelect = this.fileSelect.bind(this);
    this.fileRemove = this.fileRemove.bind(this);
  }

  fileSelect () {
		this.refs.fileinput.click();
  }
  
  fileRemove () {
		this.setState({
			progress: '0%',
			uploadedFileName: null,
			uploadedFileSize: 0,
			showSelectButton: true,
			showProgressBar: false,
			showRemoveButton: false
		});
  }
  
  changeHandler = (e) => {
		const _this = this;
		const file = e.target.files[0];
		const fileSizeNum = file ? (file.size / 1024) : null;
		const fileSize = fileSizeNum.toFixed(0);
		restful.setAxiosDefaults();
		const authToken= restful.getCookie("auth_token");
		const resturl= restful.getCookie("resturl");

		let processUpload = true;

		const adType = this.props.adType;
		switch(adType){
			case 'Image':
				if(fileSizeNum>this.state.imgSizeLimit){
					this.setState({msgInvalid: 'Image size is over the limit. Please Check.'});
					processUpload = false;
				};
				break;
			case 'Video':
				if(fileSizeNum>this.state.videoSizeLimit){
					this.setState({msgInvalid: 'Video size is over the limit. Please Check.'});
					processUpload = false;
				};
				break;
      case 'PDF':
        if(fileSizeNum>this.state.pdfSizeLimit){
          this.setState({msgInvalid: 'PDF size is over the limit. Please Check.'});
          processUpload = false;
        };
        break;
			default:
		}

		if(file&&processUpload){
			_this.setState({ showSelectButton: false, showProgressBar: true });
			_this.props.uploadingControl(true);
			let formData = new FormData();
			formData.append('file', file, file.name);
			const xhr = new XMLHttpRequest();

			xhr.upload.addEventListener('progress', function(e) {

        if (e.lengthComputable) {
          let percentComplete = e.loaded / e.total;
          percentComplete = parseInt((percentComplete * 100), 10) + '%';
          _this.setState({ progress: percentComplete});
        }

      }, false);

			xhr.open("POST", resturl+ '/file/upload', true);
			xhr.setRequestHeader('auth_token', authToken);

	    try {
	      xhr.send(formData);
	    } catch (_error) {
	      console.log(_error);
	    }

	    xhr.addEventListener('readystatechange', function(e) {
	      if (xhr.readyState === 4 && xhr.status === 200) {
	      	//console.log(xhr);

	      	// var jsonObj = eval ("(" + xhr.responseText + ")");
	      	var jsonObj = JSON.parse(xhr.responseText) ;
	      	//console.log(jsonObj);
	      	//const { fileName, fileUrl, kbSize } =  xhr.response.objs[0];
	        _this.setState({ uploadedFileName: jsonObj.objs[0].fileUrl, uploadedFileSize: fileSize, showProgressBar: false, showRemoveButton: true });
	        _this.props.uploadingControl(false);
	        if(_this.props.adType==='Video'){
	        	const video = document.getElementById("uploadedVideo");
	        	function checkVideoLoad(interVal){
	        		if(video.readyState === 4){
		        		_this.props.setVideoLength(document.getElementById("uploadedVideo").duration.toFixed(0));
		        	}else{
		        		setTimeout(checkVideoLoad, interVal);
		        	}
	        	}
	        	checkVideoLoad(100);
	        }
	      }
	    });
		}
  }
  
  render () {
		//console.log(this.state.imgSizeLimit);
		restful.setAxiosDefaults();
		var fileurl= restful.getCookie("fileurl");
		//console.log(fileurl);
		const {adType, heightPxFile} = this.props;
		let acceptFileExt = '';
		let fileSizeLimitMsg = '';
		switch(adType){
			case 'Image':
				acceptFileExt = '.png, .jpg, .gif, .jpeg';
				fileSizeLimitMsg = '*Image file size limit - ' + this.state.imgSizeLimit.toLocaleString() + 'KB';
				break;
			case 'Video':
				acceptFileExt = '.mp4';
				fileSizeLimitMsg = '*Video file size limit - ' + this.state.videoSizeLimit.toLocaleString() + 'KB';
				break;
      case 'PDF':
        acceptFileExt = '.pdf';
        fileSizeLimitMsg = '*PDF file size limit - ' + this.state.pdfSizeLimit.toLocaleString() + 'KB';
        break;
			default:
		}
		if(this.state.uploadedFileName){
			fileSizeLimitMsg = '';
		}
		return (
			<FileUploadContainer>
				<input type="file" ref="fileinput" onChange={this.changeHandler} accept={acceptFileExt} style={{ display: "none" }} />
				{ this.state.showSelectButton && !this.props.readOnly ? <FileUploadButton selectFile onClick={this.fileSelect}> Select {adType} </FileUploadButton> : null }
				{ this.state.showRemoveButton && !this.props.readOnly ? <FileUploadButton removeFile onClick={this.fileRemove}> Remove {adType} </FileUploadButton> : null }
				{ this.state.showProgressBar ? <ProgressWrap><ProgressInfo>{this.state.progress}</ProgressInfo><ProgressBar style={{ width: this.state.progress }}></ProgressBar></ProgressWrap> : null }
				<InstructionsMsg><p>{fileSizeLimitMsg}</p></InstructionsMsg>
				<InvalidMsg><p>{this.state.msgInvalid}</p></InvalidMsg>
				<FilePreviewContainer>
					{ this.state.uploadedFileName&&adType==='Image' ? <ImgContainer alt="Uploaded File" height={heightPxFile ? heightPxFile+"px" : "100%"} src={fileurl + this.state.uploadedFileName } /> : null }
					{ this.state.uploadedFileName&&adType==='Video' ? <video width="100%" controls id="uploadedVideo"><source src={fileurl + this.state.uploadedFileName } type="video/mp4" />Your browser does not support the video tag.</video> : null }
          { this.state.uploadedFileName&&adType==='PDF' ? <embed src={fileurl + this.state.uploadedFileName } width="100%" height={heightPxFile ? heightPxFile+"px" : "100%"} type="application/pdf" /> : null }
				</FilePreviewContainer>
			</FileUploadContainer>
		);
	}
}